import ExistingCertificates from "./existing-certificates-table.tsx";
import CertificateCreationTable from "./certificate-creation-table.tsx";
import { Container, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import {
  createCertificate,
  deleteCertificate,
  getCertificates,
} from "../../../http-actions/certificate-actions";
import { getModuleCollectionSummaryPoints } from "../../../http-actions/point-actions";
import { AuthContext } from "../../../providers/auth-provider";

const progress = (points, maxPoints) => {
  return `${points} / ${maxPoints} ~ ${
    Math.floor((points / maxPoints) * 1000) / 10
  }`;
};

export default () => {
  const { state: authState } = useContext(AuthContext);
  const [certificates, setCertificates] = useState(null);
  const [points, setPoints] = useState(null);

  const removeCertificate = async (certificateUuid) => {
    if (authState?.token) {
      const doDelete = confirm("About to delete a certificate. Are you sure?");

      if (doDelete) {
        await deleteCertificate(authState.token, certificateUuid);
        await new Promise((res) => setTimeout(res, 1000));
        await fetchCertificates();
      }
    }
  };

  const fetchCertificates = async () => {
    if (authState?.token) {
      const certs = await getCertificates(authState.token);
      setCertificates(certs);
    }
  };

  const fetchPoints = async () => {
    if (authState?.token) {
      const pts = await getModuleCollectionSummaryPoints(authState.token);
      setPoints(pts);
    }
  };

  useEffect(() => {
    if (authState?.token) {
      fetchCertificates();
      fetchPoints();
    }
  }, [authState?.token]);

  const createAndFetchCertificates = async (
    course: string,
    collection?: string,
  ) => {
    if (authState?.token) {
      await createCertificate(authState.token, { course, collection });
      await new Promise((res) => setTimeout(res, 1000));
      await fetchCertificates();
    }
  };

  return (
    <Container maxWidth={"lg"} sx={{ pt: 4 }}>
      <Typography variant="h2">
        <Trans i18nKey="myCertificates" />
      </Typography>
      <ExistingCertificates
        certificates={certificates}
        progressFunction={progress}
        removeCertificate={removeCertificate}
      />
      <CertificateCreationTable
        points={points}
        createAndFetchCertificates={createAndFetchCertificates}
        progressFunction={progress}
      />
    </Container>
  );
};
