import React from "react";
import {
  Box,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CertificateLayout from "../../components/certificates/view/certificate-layout";

import config from "../../config";

const CertificateValidator = (certificateUuid: String | null) => {
  // TODO: implement automatic download of certificate from api
  

  return (
    <Container sx={{ pt: 4 }}>
      <Paper sx={{ m: 4, p: 4 }}>
        <Typography variant="h2">
          Certificate validation page
        </Typography>
        <Typography sx={{ pt: 4, pb: 2 }}>
          Certificate with the UUID {certificateUuid}{" "}
          should be downloaded downloaded in a moment..
        </Typography>
        <Link
          href={config.OPENCS_API_BASE_URL +
            "/api/certificates/" +
            certificateUuid}
        >
          Click here if the download does not start automatically.
        </Link>
      </Paper>
    </Container>
  );
};

export default () => {
  if (typeof window !== "undefined") {
    const searchString = window.location.search;
    const params = new URLSearchParams(searchString);
    if (params.has("v")) {
      const certificateUuid = params.get("v");
      return CertificateValidator(certificateUuid);
    }
  }

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <CertificateLayout />
    </Box>
  );
};
