import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import config, {
  MODULE_COLLECTIONS_BY_TITLE,
  MultiCourseModuleCollectionI,
} from "../../../config";

export default (props) => {
  let certificates = props.certificates;

  React.useEffect(() => {
    certificates = props.certificates;
  }, [props.certificates]);

  console.log("In existing certificates table...");
  console.log(certificates);

  return (
    <>
      <Typography variant="h3" sx={{ pl: 2, pt: 4, pb: 2 }}>
        <Trans i18nKey="existingCertificates" />
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="existing-certificates">
          <TableHead>
            <TableRow>
              <TableCell>Course</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell>Download</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(certificates) &&
              certificates.map((certificate) => {
                if (!certificate.pointData) {
                  return null;
                }
                const { moduleCollection, moduleName } = certificate
                  ?.certificateData;
                const isMultiCourse = Boolean(moduleName);

                const course = isMultiCourse
                  ? `${moduleCollection} / ${
                    (
                      MODULE_COLLECTIONS_BY_TITLE[
                        moduleCollection
                      ] as MultiCourseModuleCollectionI
                    ).courseNames[moduleName]
                  }`
                  : moduleCollection;

                const progress = props.progressFunction(
                  certificate?.pointData?.points,
                  certificate?.pointData?.maxPoints,
                );

                const date = new Date(certificate.createdAt);

                return (
                  <TableRow
                    key={certificate.certificateUuid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{course}</TableCell>
                    <TableCell>{date.toLocaleString()}</TableCell>
                    <TableCell>{progress}%</TableCell>
                    <TableCell>
                      <Link
                        href={config.OPENCS_API_BASE_URL +
                          "/api/certificates/" +
                          certificate.certificateUuid}
                      >
                        Download
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.removeCertificate(
                            certificate.certificateUuid,
                          );
                        }}
                      >
                        Delete
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
