import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const getCertificates = (token: string) => {
    console.log(config.OPENCS_API_BASE_URL);
    console.log(`Retrieving certificates for user...`);
    return axios
        .get(`/api/certificates`, {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(
                    response,
                    `Retrieved certificates for user...`,
                ),
            handleResponseError,
        );
};


export const getCertificate = (token: string, certificateUuid: string) => {
    console.log(`Retrieving certificate ${certificateUuid} ...`);
    return axios
        .get(`/api/certificates/${certificateUuid}`, {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(
                    response,
                    `Retrieved certificate for user...`,
                ),
            handleResponseError,
        );
};

export const deleteCertificate = (token: string, certificateUuid: string) => {
    console.log(`Retrieving certificate ${certificateUuid} ...`);
    return axios
        .delete(`/api/certificates/${certificateUuid}`, {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(
                    response,
                    `Deleted certificate for user...`,
                ),
            handleResponseError,
        );
};

export const createCertificate = (token: string, data: {course: string, collection?: string}) => {
    console.log(`Asking to create certificate for course ${data.course}..`);
    console.log("TODO: how to identify course?");
    return axios
        .post(`/api/certificates`, data, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, `Asked to create certificate for course ${data.course}`),
            handleResponseError,
        );
};
