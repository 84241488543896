import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import {
  MODULE_COLLECTIONS_BY_TITLE,
  MultiCourseModuleCollectionI,
} from "../../../config";
import Button from "../../button";

const INCLUDED_SINGLE_COURSES = [
  "Designing and Building Scalable Web Applications",
  "Device-Agnostic Design",
  "Web Software Development",
];

const INCLUDED_MULTI_COURSE_SLUGS = [
  "introduction-to-programming",
  "data-and-information",
  "internet-and-browser-applications",
  "mobile-application-development",
  "rust",
];

const createSingleCourseEntry = (
  createAndFetchCertificates,
  collectionName,
  progress,
) => {
  if (!INCLUDED_SINGLE_COURSES.includes(collectionName)) {
    return null;
  }
  return (
    <TableRow key={`${collectionName}-${progress}`}>
      <TableCell>{collectionName}</TableCell>
      <TableCell>
        {progress}%
      </TableCell>
      <TableCell>
        <Button
          variant="string"
          onClick={async () => {
            await createAndFetchCertificates(
              collectionName,
            );
          }}
          disabled={progress < 0.0}
        >
          Create
        </Button>
      </TableCell>
    </TableRow>
  );
};

const createMultiCourseCourseEntry = (
  createAndFetchCertificates,
  collectionName,
  moduleName,
  progress,
) => {
  if (!INCLUDED_MULTI_COURSE_SLUGS.includes(moduleName)) {
    console.log(moduleName);
    console.log("Not in multi courses!!");
    return null;
  }
  return (
    <TableRow
      key={`${collectionName}-${progress}-${moduleName}`}
    >
      <TableCell>
        {collectionName} / {(
          MODULE_COLLECTIONS_BY_TITLE[
            collectionName
          ] as MultiCourseModuleCollectionI
        ).courseNames[moduleName]}
      </TableCell>
      <TableCell>
        {progress}%
      </TableCell>
      <TableCell>
        <Button
          variant="string"
          onClick={() => {
            createAndFetchCertificates(
              moduleName,
              collectionName,
            );
          }}
          disabled={progress < 0.0001}
        >
          Create
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default (props) => {
  let points = props.points;
  let createAndFetchCertificates = props.createAndFetchCertificates;
  const progressFunction = props.progressFunction;

  React.useEffect(() => {
    points = props.points;
    createAndFetchCertificates = props.createAndFetchCertificates;
  }, [props.points, props.createAndFetchCertificates]);

  return (
    <>
      <Typography variant="h3" sx={{ pl: 2, pt: 4, pb: 2 }}>
        <Trans i18nKey="createCertificate" />
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="current-course-points">
          <TableHead>
            <TableRow>
              <TableCell>Course</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {points &&
              points.moduleCollections &&
              Object.entries(points.moduleCollections).map(
                ([collectionName, val], i) => {
                  switch (val.data.type) {
                    case "single-course":
                      return createSingleCourseEntry(
                        createAndFetchCertificates,
                        collectionName,
                        progressFunction(
                          val?.data?.points ?? 0,
                          val.data.maxPoints,
                        ),
                      );
                    case "multi-course":
                      return Object.entries(val.modules)
                        .map(
                          ([moduleName, moduleData]) =>
                            createMultiCourseCourseEntry(
                              createAndFetchCertificates,
                              collectionName,
                              moduleName,
                              progressFunction(
                                moduleData?.points ?? 0,
                                moduleData?.maxPoints,
                              ),
                            ),
                        );
                  }
                },
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
